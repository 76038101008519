import {
  DocumentMetadataResponse,
  FindFreightQuery,
  FindFreightQueryHandler,
  FreightBasicResponse,
  FreightDetailedResponse,
  SearchDocumentMetadataQuery,
  SearchDocumentMetadataQueryHandler,
  SearchInProgressFreightsQuery,
  SearchInProgressFreightsQueryHandler,
  SearchNotInProgressFreightsQuery,
  SearchNotInProgressFreightsQueryHandler,
} from '@okcargo/query-processor';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { BaseQueryRepository } from 'src/app/common/infrastructure/query.repository';

@Injectable({
  providedIn: 'root',
})
export class FreightShipQueryService {
  private readonly searchInProgressFreightsQueryHandler: SearchInProgressFreightsQueryHandler;
  private readonly searchNotInProgressFreightsQueryHandler: SearchNotInProgressFreightsQueryHandler;
  private readonly findFreightQueryHandler: FindFreightQueryHandler;
  private readonly searchDocumentMetadataQueryHandler: SearchDocumentMetadataQueryHandler;

  constructor(private readonly queryRepository: BaseQueryRepository) {
    this.searchInProgressFreightsQueryHandler = new SearchInProgressFreightsQueryHandler(queryRepository);
    this.searchNotInProgressFreightsQueryHandler = new SearchNotInProgressFreightsQueryHandler(queryRepository);
    this.findFreightQueryHandler = new FindFreightQueryHandler(queryRepository);
    this.searchDocumentMetadataQueryHandler = new SearchDocumentMetadataQueryHandler(queryRepository);
  }

  searchInProgressFreights(query: SearchInProgressFreightsQuery): Promise<FreightBasicResponse[]> {
    return this.searchInProgressFreightsQueryHandler.handle(query);
  }

  searchFinishedFreights(query: SearchNotInProgressFreightsQuery): Promise<FreightBasicResponse[]> {
    return this.searchNotInProgressFreightsQueryHandler.handle(query);
  }

  findFreight(query: FindFreightQuery): Observable<FreightDetailedResponse> {
    return from(this.findFreightQueryHandler.handle(query));
  }

  searchDocumentMetadata(query: SearchDocumentMetadataQuery): Promise<DocumentMetadataResponse[]> {
    return this.searchDocumentMetadataQueryHandler.handle(query);
  }
}
