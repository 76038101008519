import { inject, Injectable, signal } from '@angular/core';
import { AcceptFreightOfferCommand, RejectFreightOfferCommand } from '@okcargo/command-processor';
import {
  CriteriaPayload,
  DocumentMetadataFieldNames,
  DocumentMetadataResponse,
  FilterCombinator,
  FilterComparator,
  FilterPayload,
  FindFreightQuery,
  FreightFieldNames,
  OrderPayload,
  OrderType,
  SearchDocumentMetadataQuery,
  SearchInProgressFreightsQuery,
  SearchNotInProgressFreightsQuery,
} from '@okcargo/query-processor';
import { BehaviorSubject, forkJoin, from, map, Observable, of, switchMap } from 'rxjs';
import { ICompany, ICostCenter } from 'src/app/account/domain/account.model';
import { AccountService } from '../../account/application/account.service';

import { DownloadDocumentService } from 'src/app/common/infrastructure/download-document.service';
import {
  EFreightStatus,
  getEmptyDefaultFreightBasic,
  getEmptyDefaultFreightDetail,
  IDigitizedDeliveryNotes,
  IFreightBasic,
  IFreightDetail,
} from 'src/app/freight/domain/freight.model';
import { transformToIFreightBasic, transformToIFreightDetail } from 'src/app/freight/infrastructure/freight-mapper';
import { FreightShipCommandService } from '../infrastructure/freight-ship-command.service';
import { FreightShipQueryService } from '../infrastructure/freight-ship-query.service';

@Injectable({
  providedIn: 'root',
})
export class FreightShipService {
  private readonly accountService = inject(AccountService);
  private readonly downloadDocumentService = inject(DownloadDocumentService);
  private readonly freightShipQueryService = inject(FreightShipQueryService);
  private readonly freightShipCommandService = inject(FreightShipCommandService);

  page: number = 0;
  size: number = 300;

  private readonly _accountCompanySubject = new BehaviorSubject<ICompany | null>(null);
  private _companyId: number | undefined;

  private readonly _freightsShipPending$ = new BehaviorSubject<IFreightBasic[]>([]);
  private readonly _freightsShipInProgress$ = new BehaviorSubject<IFreightBasic[]>([]);
  private readonly _freightsShipFinished$ = new BehaviorSubject<IFreightBasic[]>([]);

  public freightsShipNotFinishedLoading = signal(true);
  public freightsShipFinishedLoading = signal(true);

  private readonly _lastRefreshFreightShipDetailTimestamp = signal<number>(Date.now());

  constructor() {
    this.accountService.account$.subscribe((account) => {
      if (account.company) {
        this._companyId = account.company.id;
        this._accountCompanySubject.next(account.company);
      }
    });
  }

  public get freightsShipPending$(): BehaviorSubject<IFreightBasic[]> {
    return this._freightsShipPending$;
  }

  public get freightsShipInProgress$(): BehaviorSubject<IFreightBasic[]> {
    return this._freightsShipInProgress$;
  }

  public get freightsShipFinished$(): BehaviorSubject<IFreightBasic[]> {
    return this._freightsShipFinished$;
  }

  public get costCenters$(): Observable<ICostCenter[]> {
    return this._accountCompanySubject.asObservable().pipe(map((company) => company?.costCenters || []));
  }

  // TODO use it in components template (freightDetail)
  public get lastRefreshFreightShipDetailTimestamp(): number {
    return this._lastRefreshFreightShipDetailTimestamp();
  }

  public refreshFreightsShipNotFinished(): void {
    this.searchFreightsShipNotFinished();
  }

  public refreshFreightsShipFinished(): void {
    this.searchFreightsShipFinished();
  }

  public refreshFreightShipDetail(): void {
    // Trigger/modifies the signal's content so that the component (they has
    // the freight id) makes the corresponding call in the constructor's effect()
    this._lastRefreshFreightShipDetailTimestamp.set(Date.now());
  }

  public findFreightShipDetail$(id: string): Observable<IFreightDetail> {
    const query = {
      id: id,
    } as FindFreightQuery;

    return from(this.freightShipQueryService.findFreight(query)).pipe(
      switchMap((freightDetailedResponse): Observable<IFreightDetail> => {
        const freight: IFreightDetail = getEmptyDefaultFreightDetail();
        const transformedFreight = transformToIFreightDetail(freightDetailedResponse, freight);

        if (freightDetailedResponse.numberOfDeliveryNotes > 0) {
          return this.searchFreightDetailDocuments$(freightDetailedResponse.id).pipe(
            map((documents) => {
              // TODO review this, mapper necessary??? probably yes
              const deliveryNotes = documents.filter((document) => document.type === 'DELIVERY_NOTE');
              transformedFreight.digitizedDeliveryNotes = deliveryNotes as unknown as IDigitizedDeliveryNotes[];
              return transformedFreight;
            }),
          );
        } else {
          return of(transformedFreight);
        }
      }),
    );
  }

  public async acceptPrice(freight: any): Promise<void> {
    const command = {
      assignationId: freight.shipperCarrierAssignationId,
      freightId: freight.id,
    } as AcceptFreightOfferCommand;
    this.freightShipCommandService.acceptFreightOffer(command);
  }

  public async rejectPrice(freight: any): Promise<void> {
    const command = {
      assignationId: freight.shipperCarrierAssignationId,
      freightId: freight.id,
    } as RejectFreightOfferCommand;
    this.freightShipCommandService.rejectFreightOffer(command);
  }

  private searchFreightsShipNotFinished(): void {
    const query = {
      criteriaPayload: {
        order: [
          {
            field: FreightFieldNames.STARTING_AT,
            type: OrderType.ASC,
          } as OrderPayload,
        ],
        // only freights assigned to others
        filters: [
          {
            combinator: FilterCombinator.NONE,
            field: FreightFieldNames.CARRIER_ID,
            comparator: FilterComparator.NEQ,
            value: this._companyId,
            group: null,
          },
        ],
        pageIndex: this.page,
        pageSize: this.size,
      } as unknown as CriteriaPayload,
    } as SearchInProgressFreightsQuery;

    from(this.freightShipQueryService.searchInProgressFreights(query))
      .pipe(
        map((freights) =>
          freights.map((freightBasicResponse): IFreightBasic => {
            const freight: IFreightBasic = getEmptyDefaultFreightBasic();
            return transformToIFreightBasic(freightBasicResponse, freight);
          }),
        ),
        map((freights) => {
          // TODO review if freight.status !== EFreightStatus.FINISHED is necessary because there were times when the status of the last stop was not consistent with the freight status
          const pendingFreights = freights.filter(
            (freight) =>
              freight.status === EFreightStatus.OFFER_REQUESTED || freight.status === EFreightStatus.OFFER_SENT,
          );

          const inProgressFreights = freights.filter(
            (freight) =>
              freight.status !== EFreightStatus.OFFER_REQUESTED && freight.status !== EFreightStatus.OFFER_SENT,
          );

          return { pendingFreights, inProgressFreights };
        }),
      )
      .subscribe(({ pendingFreights, inProgressFreights }) => {
        this._freightsShipPending$.next(pendingFreights);
        this._freightsShipInProgress$.next(inProgressFreights);
        this.freightsShipNotFinishedLoading.set(false);
      });
  }

  private searchFreightsShipFinished(): void {
    const query = {
      criteriaPayload: {
        order: [
          {
            field: FreightFieldNames.STARTING_AT,
            type: OrderType.DESC,
          } as OrderPayload,
        ],
        // only freights assigned to others
        filters: [
          {
            combinator: FilterCombinator.NONE,
            field: FreightFieldNames.CARRIER_ID,
            comparator: FilterComparator.NEQ,
            value: this._companyId,
            group: null,
          },
        ],
        pageIndex: this.page,
        pageSize: this.size,
      } as unknown as CriteriaPayload,
    } as SearchNotInProgressFreightsQuery;

    from(this.freightShipQueryService.searchFinishedFreights(query))
      .pipe(
        map((freights) =>
          freights.map((freightBasicResponse): IFreightBasic => {
            const freight: IFreightBasic = getEmptyDefaultFreightBasic();
            return transformToIFreightBasic(freightBasicResponse, freight);
          }),
        ),
      )
      .subscribe((transformedFreights) => {
        this._freightsShipFinished$.next(transformedFreights);
        this.freightsShipFinishedLoading.set(false);
      });
  }

  private searchFreightDetailDocuments$(freightId: string): Observable<DocumentMetadataResponse[]> {
    const query = {
      criteriaPayload: {
        order: [],
        filters: [
          {
            field: DocumentMetadataFieldNames.AGGREGATE_ID,
            comparator: FilterComparator.EQ,
            value: freightId,
            combinator: FilterCombinator.NONE,
          } as FilterPayload,
        ], // FilterPayload[]; create a filter by history or in-progress
        pageIndex: 0,
        pageSize: 30,
      } as unknown as CriteriaPayload,
    } as SearchDocumentMetadataQuery;

    return from(this.freightShipQueryService.searchDocumentMetadata(query)).pipe(
      switchMap((documents: DocumentMetadataResponse[]) => {
        const documentObservables = documents.map((document) => {
          const url = this.downloadDocumentService.getDownloadDocumentURL(document);
          return of({ ...document, url });
        });
        return forkJoin(documentObservables);
      }),
    );
  }

  /////**** pending review */

  /*** BELOW, NOT IN USE */

  // }

  // move to model? infrastructure?
  // getEmptyFreightUpload(): CreateFreightWithStopsAndAssignationsCommand {

  /*

  getEmptyFreightPost() {
    const firstStop = this.getEmptyStop(FreightStopType.LOAD, 0);
    const lasStop = this.getEmptyStop(FreightStopType.UNLOAD, 1);

    const companyData = this.companyData(this._accountCompany);

    return {
      id: uuidv4(),
      type: FreightType.FULL,
      transportInfoPayload: {
        vehicleTypes: [], // "CANVAS" // FORM!!!!
        loadingTypes: [], // "REAR" // FORM!!!!
        transportGoodsType: FreightTransportGoodsType.WASTE,
        exchangePlatform: false, //null,
        transportTemperatureRanges: [],
        transportTemperatureUnit: TemperatureUnit.CELSIUS,
      },
      documentType: FreightDocumentType.ECDP, //"ECDP",
      instructions: '', // FORM!!!!
      deliveryNoteInPaper: false, // FORM!!!!
      carrierObservations: '', // // FORM!!!!
      freightTemplateName: '', // null,
      stops: [firstStop, lasStop],
      assignations: [
        {
          assignationId: uuidv4(),
          assignationType: FreightAssignationType.COLLABORATOR,
          assignedFromPayload: {
            partyPayload: companyData as unknown as PartyPayload,
            costCenterPayload: {
              id: 1008, // FORM!!!!
              m2mReference: 'CENTRO', // FORM!!!!
            },
            deliveryNoteInPaper: true, // FORM!!!!
            freightM2mId: null,
            freightM2mReference: 'referenciaCarga', // FORM!!!!
          },
          assignedToPayload: {
            partyPayload: this.companyData(null) as unknown as PartyPayload,
            costCenterPayload: null,
            freightM2mId: null,
            freightM2mReference: 'Expedición', // FORM!!!!
          },
          pricePayload: null,
          deliveryNoteInPaper: true, // FORM!!!!
          contractual: true,
          fromOkCargo: false,
          toOkCargo: true,
          priceAccepted: false,
        },
      ],
      journeyId: uuidv4(),
      source: FreightSource.WEB_FORM, //'WEB_FORM',
    };
  }

  */
  /*
  private getEmptyStop(
    type: FreightStopType,
    position: number,
    // ): FreightStopPayload {
  ) {
    return {
      stopId: uuidv4(),
      position: 0, // FORM!!!!
      type: type, // FORM!!!!
      addressPayload: {
        // id: 0, // not necessary
        // national: true, // not necessary
        street: '', // FORM!!!!
        city: '', // FORM!!!!
        postalCode: '', // FORM!!!!
        state: '', // FORM!!!!
        country: '', // FORM!!!!
        pointPayload: {
          latitude: 0, // FORM!!!!
          longitude: 0, // FORM!!!!
        },
      },
      companyPayload: {
        id: 0, // null,
        name: '',
        taxId: '',
        phone: '',
        addressPayload: null,
        m2mId: null,
      },
      since: '2024-08-01T22:00:00.000Z', // FORM!!!!
      until: '2024-08-02T00:00:00.000Z', // FORM!!!!
      tare: null, // FORM!!!!
      grossWeight: null, // FORM!!!!
      netWeight: 1000, // FORM!!!!
      packages: 10, // FORM!!!!
      goodsDescription: 'descripción', // FORM!!!!
      reference: 'refcarga', // FORM!!!!
      unloadedNetWeight: null, // FORM!!!!
      unloadedPackages: null, // FORM!!!!
    };
  }

  // private companyData(): PartyPayload {
  private companyData(accountCompany: ICompany | null) {
    return {
      id: accountCompany?.id ?? null,
      name: accountCompany?.name ?? '',
      taxId: accountCompany?.taxNumber ?? '',
      phone: accountCompany?.phone ?? '',
      addressPayload: {
        id: null,
        street: accountCompany?.streetAddress ?? '',
        city: accountCompany?.city ?? '',
        postalCode: accountCompany?.postalCode ?? '',
        state: accountCompany?.stateProvince ?? '',
        country: accountCompany?.country ?? '',
        pointPayload: null,
      },
      m2mId: null,
    };
  }

  */
}
