import { Injectable } from '@angular/core';
import {
  AcceptFreightOfferCommand,
  AcceptFreightOfferCommandHandler,
  RejectFreightOfferCommand,
  RejectFreightOfferCommandHandler,
} from '@okcargo/command-processor';
import { BaseCommandRepository } from 'src/app/common/infrastructure/command.repository';

@Injectable({ providedIn: 'root' })
export class FreightShipCommandService {
  private readonly acceptFreightOfferCommandHandler: AcceptFreightOfferCommandHandler;
  private readonly rejectFreightOfferCommandHandler: RejectFreightOfferCommandHandler;

  constructor(commandRepository: BaseCommandRepository) {
    this.acceptFreightOfferCommandHandler = new AcceptFreightOfferCommandHandler(commandRepository);
    this.rejectFreightOfferCommandHandler = new RejectFreightOfferCommandHandler(commandRepository);
  }

  acceptFreightOffer(command: AcceptFreightOfferCommand): Promise<void> {
    console.log('Accepting freight offer ', command.assignationId);
    return this.acceptFreightOfferCommandHandler.handle(command);
  }

  rejectFreightOffer(command: RejectFreightOfferCommand): Promise<void> {
    console.log('Rejecting freight offer ', command.assignationId);
    return this.rejectFreightOfferCommandHandler.handle(command);
  }
}
